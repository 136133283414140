@import '../conf';

.homeSlider {
    background: $color-background-gray;
    margin-top: vw(80px);
    padding-bottom: 380px;
}
.homeSlider .container {
    padding-top: 45px;
    padding-bottom: 60px;
}
.homeSlider__photos {
    display: flex;
    flex-direction: column;
}

.homeSlider__heading {
    margin-bottom: 1.25rem;
}

.homeSlider__text {
    margin-bottom: 3rem;
    max-width: 1000px;

    p {
        margin-bottom: 1.5rem;
    }
}


.homeSlider__thumbs .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.homeSlider__main .swiper-slide {
    background-repeat: no-repeat;
    background-position: center;
    border-radius: $border-radius;
}

.homeSlider__thumbs .homeSlider__thumbnail {
    padding-left: 6px;
    
    img {
        width: 90%;
    }
}

@media screen and (max-width: 767px) {
    .homeSlider__thumbs {
        position: absolute !important;
        left: -9999em !important;
        overflow: hidden !important;
    }

    .homeSlider__main {
        width: 100%;
        min-height: 320px;
        height: vw(750px);
        max-height: 750px;
    }
    .homeSlider__main .swiper-slide {
        background-size: cover;
        background-position: center;
    }
}



$ratio: .45;

@media screen and (min-width: 768px) {

    .homeSlider__photos {
        $base-w: 760px;

        width: 100%;
        height: 40vw;
        max-height: 570px;

        display: flex;
        flex-direction: row;
        margin-left: auto;
        margin-right: auto;
    }

    .homeSlider__thumbs {
        background: lighten($color-background-gray, .5%);
        box-shadow: 0 0 38px rgba($color-content, .075);
        padding-top: 5px;
        padding-bottom: 3px;
    }
    .homeSlider__thumbs.swiper-container {
        width: 24%;
        height: 100%;
        margin-right: vw(20px);
    }
    .homeSlider__thumbs .swiper-scrollbar {
        right: 6px !important;
        background: #798690;
        border-radius: $border-radius;
    }
    .homeSlider__thumbs .swiper-scrollbar-drag {
        background: #233142;
        border-radius: $border-radius;
    }
    .homeSlider__thumbs .swiper-slide-thumb-active {
        background: $color-white;
        box-shadow: 0 0 38px rgba($color-content, .075);
    }

    .homeSlider__main.swiper-container {
        flex: 1;
    }

    .homeSlider__main .swiper-slide {
        background-size: cover;
    }
}